<script>
import { defineComponent } from '@vue/composition-api'
import { useEndpoints } from '@/composables'

import { TemplateDetails } from '@/composites/mail-template'
import { getId } from '@/utils/UrlHelper'
import { httpClient } from '@/services'

export default defineComponent({
  name: 'MailTemplateDetail',
  components: {
    BaseLayout: () => import('@/views/shared/BaseLayout.vue'),
    TemplateDetails,
  },
  methods: {
    async fetchTemplate(id) {
      const { data } = await httpClient.get(
        useEndpoints.mail.template.details(id)
      )
      if (data) {
        this.$store.dispatch('mailTemplate/fetchTemplate', data)
      }
    },
  },

  created() {
    const id = getId()
    this.fetchTemplate(id)
  },
  render() {
    return (
      <BaseLayout>
        <TemplateDetails />
      </BaseLayout>
    )
  },
})
</script>
